<template>

    <span> 
      <v-text-field
        :label="label"
        v-model="originalValue"
        :persistent-hint="!apikey"
        :readonly="true"
        :hint="hint"
        :loading="richiestaInCorso"
        @change=onChange>
        <template v-slot:append-outer>
          <v-icon color="success" @click="onGeneraApikey" title="Genera un nuovo username">mdi-file-key-outline</v-icon>
        </template>
      </v-text-field>

<!--       <v-text-field
        label="Cloudant password"
        v-model="passwordValue"
        :readonly="true">
      </v-text-field>  --> 
    <dialog-conferma
      :show="dialogConferma"
      title="Modifica chiave"
      :confirmText="confirmText"
      message="Attenzione! Il precedente valore non sarà più valido. Confermi Operazione ?"
      @dialog-confirm="onConferma"
      @dialog-cancel="dialogConferma=false"
    />      
    </span>   

</template>

<script>
  import DialogConferma from '@/components/dialogConferma'
  import licenze from '@/services/licenzeService'

  export default {
    components: {
      DialogConferma
    },
    name: 'apikey',
    props: {
      apikey: String,
      trigger: Number,
      short: {
        type: Boolean,
        default: false
      },
      label: {
        type: String,
        default: 'Cloudant Username'
      },
      hint: {
        type: String,
        default: 'Crea un nuovo username'
      },
      istanzaDb: String,
      baseDb: String
    },
    data: (vm) => ({
      richiestaInCorso: false,
      originalValue: vm && vm.apikey ? vm.apikey : '',
      dialogConferma: false,
      confirmText: 'SI'
    }),
    mounted() {
      this.originalValue = this.apikey || ''
    },
    watch: {
      showDrawer(value) {
        if (value && this.item && this.item.value) {
          this.originalValue = JSON.parse(JSON.stringify(this.item.value))
        }
      },
      trigger() {
        this.originalValue = this.apikey || ''
      }
    },
    computed: {
      showDrawer() {
        return this.$store.getters.rightDrawer
      }
    },
    methods: {
      async onGeneraApikey() {
        if (this.apikey) {
          this.dialogConferma = true
        } else {
          await this.generaApikey()
        }
      },
      async onConferma() {
        await this.generaApikey()
        this.dialogConferma = false
      },
      async generaApikey() {
        this.$emit('loading', true)
        const newcode = await licenze.creaCloudantApiPairs(this.baseDb, this.istanzaDb, false)
        this.originalValue = newcode.key
        this.onChange(newcode.key, newcode.password)
        this.$emit('loading', false)
      },
      async onChange(value, password) {
        if (value) {
          this.originalValue = value
          this.$emit('change', { key: value, password })
          this.update
        }
      }
    }
  }
</script>
<template>
  <v-card class="mx-auto" max-width="300" min-height="380" outlined :loading="loading">
      <template slot="progress">
        <v-progress-linear color="deep-purple" height="3" indeterminate></v-progress-linear>
      </template>
      <v-list-item three-line>
        <v-list-item-content>
          <div class="text-overline">{{categoria}}<v-btn icon @click="onRefresh"><v-icon>mdi-refresh</v-icon></v-btn></div> 
          <v-list-item-subtitle style="min-height: 32px;">{{descrizione}}</v-list-item-subtitle>
          <v-list-item-content>
            <v-list-item-title>
              <v-file-input
                class="mt-2 text-caption"
                accept=".mdb"
                label="File Access"
                show-size
                outlined
                dense
                prepend-icon=""
                prepend-inner-icon="$file"
                :hint="nomefile"
                :persistent-hint="!!nomefile"
                @change="onChange">
              </v-file-input>
              <v-file-input
                 v-if="modello === 'magosimpr' && dbRemote === 'prodotti'"
                class="mt-2 text-caption"
                accept=".mdb"
                label="File dbc"
                show-size
                outlined
                dense
                prepend-icon=""
                prepend-inner-icon="$file"
                :hint="nomeFileDbc"
                :persistent-hint="!!nomeFileDbc"
                @change="onChangeDbc">
              </v-file-input>                  
            </v-list-item-title>
            <v-list-item-title><v-switch v-model="aggiornaDb" label="Aggiorna database" class="ml-3" dense></v-switch></v-list-item-title>
            <v-list-item-title><v-switch v-model="removeDb" label="Cancella esistente" class="ml-3" dense></v-switch></v-list-item-title>
            <v-list-item-subtitle class="light-blue--text text--lighten-1">{{ statusMessage }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item-content>
      </v-list-item>
      <v-card-actions>
        <v-badge bordered :color="notAllowed ? 'error' : 'success'" :icon="icon" overlap :value="icon">
          <v-btn outlined text @click="onSeleziona" :disabled="disabled || notAllowed">{{comando}}</v-btn>
        </v-badge>
      </v-card-actions>
      <dialog-conferma
        :show="dialogConferma"
        :title="titoloConferma"
        :message="messaggioConferma"
        :confirmText="confirmText"
        :onlyOk="onlyOk"
        @dialog-confirm="onConferma"
        @dialog-cancel="dialogConferma=false; onlyOk=false"
      />
   <!-- </form> -->
  </v-card>
</template>

<script>
import DialogConferma from '@/components/dialogConferma'
import migrazione from '@/services/migrazioneService'

export default {
  components: {
    DialogConferma
  },
  props: {
    id: String,
    dbRemote: String,
    categoria: String,
    descrizione: String,
    comando: {type: String, default: 'Esegui'},
    disabled: Boolean,
    notAllowed: Boolean,
    modello: String,
    cliente: Object
  },
  data(vm) {
    return {
      loading: false,
      aggiornaDb: true,
      removeDb: false,
      nomefile: '',
      nomeFileDbc: '',
      dialogConferma: false,
      titoloConferma: '',
      messaggioConferma: '',
      confirmText: 'SI',
      onlyOk: false,
      onConferma: vm.selectConfirm,
      intervalStato: null,
      statusMessage: ''
    }
  },
  computed:{
    icon() {
      if (this.notAllowed) return 'mdi-lock'
      if (this.disabled) return 'mdi-check-outline'
      return ''
    },
    licenza() {
      return this.cliente && this.cliente.licenze[0]
    }
  },
  mounted() {

  },
  methods: {
    onRefresh() {
      this.verificaStatoMigrazione()
    },
    //async onSeleziona(e) {
      // this.$emit('select', this.id, this.nomefile, this.aggiornaDb, this.removeDb)
    //},
    verificaStatoMigrazione() {
      // return // TODO: E' necessario avviare la procedura in background come processo child, altrimenti migrazione non risponde durante l'elaborazione...
      if (!this.modello) return
      this.intervalStato = setInterval(async () => {
        const stati = await migrazione.statoMigrazione(this.modello)
        const isRunning = stati[this.id]
        this.statusMessage = stati[`${this.id}Status`]
        if (!isRunning) {
          this.loading = false
          clearInterval(this.intervalStato)
          this.intervalStato = null
        } else {
          this.loading = true
        }
      }, 10000)
    },
    operazioneEseguitaConfirm() {
      this.dialogConferma = false
      this.confirmText= 'SI'
      this.onlyOk = false
    },
    operazioneEseguita(messaggio) {
      this.titoloConferma = 'Operazione eseguita'
      this.messaggioConferma = messaggio || 'Operazione eseguita con successo ! '
      this.confirmText= 'OK'
      this.onlyOk = true
      this.onConferma = this.operazioneEseguitaConfirm
      this.dialogConferma = true
    },
    onSeleziona() {
      if (this.modello === '' || this.nomefile === '') {
        this.titoloConferma = 'Dati non completi'
        this.messaggioConferma = 'Modello e database devono essere selezionati prima di procedere!'
        this.confirmText= 'OK'
        this.onlyOk = true
        this.onConferma = this.operazioneEseguitaConfirm
        this.dialogConferma = true
        return
      }

      this.titoloConferma = 'Migrazione: ' + this.categoria
      this.messaggioConferma = 'Confermi esecuzione di migrazione ?'
      this.onConferma = this.selectConfirm
      this.dialogConferma = true
      // this.$emit('select',  this.id, this.nomefile, this.aggiornaDb, this.removeDb))
    },
    async selectConfirm() {
      this.loading = true
      this.dialogConferma = false
      const remote = `${this.licenza.baseDb}_${this.dbRemote}`
      const result = await migrazione.eseguiMigrazione(this.modello, this.id, this.nomefile, remote , this.aggiornaDb, this.removeDb, this.nomeFileDbc)
      this.loading = false
      let messaggio = result.messaggio || 'Operazione eseguita con successo!'
/*       if (this.id == 'causali') {
        messaggio += '<br/><br/>Completare manualmente l\'elenco delle causali, inserendo i parametri necessari per il corretto funzionamento !!'
      } */
      if (this.id == 'movimenti' || this.id == 'scontrini' || (this.id == 'prodotti' && ['bennet', 'fatalight', 'magosimpr'].includes(this.modello))) {
        messaggio = 'L\'operazione è stata schedulata!<br/><br/>L\'attività richiede alcumi minuti per il completamento e sarà eseguita in background.'
        this.loading = true
        this.verificaStatoMigrazione()
      }
      this.operazioneEseguita(messaggio)
    },    
    async onChange(file) {
      this.nomefile = await this.carica(file)
/*       if (!file) {
        return
      }
      this.loading = true

      const form_data = new FormData();
      form_data.append('access_db', file, `${this.licenza.baseDb}_${file.name}`)
      this.nomefile = await migrazione.inviaFileMdbForm(form_data)

      this.loading = false */
    },
    async onChangeDbc(file) {
      this.nomeFileDbc = await this.carica(file)
    },
    async carica(file) {
      if (!file) {
        return
      }
      this.loading = true

      const form_data = new FormData();
      form_data.append('access_db', file, `${this.licenza.baseDb}_${file.name}`)
      const nomefile = await migrazione.inviaFileMdbForm(form_data)

      this.loading = false
      return nomefile
    }
  }
}
</script>

<template>
  <v-data-table
    :headers="headers"
    :items="siti"
    sort-by="tipo"
    class="elevation-1"
    dense
    hide-default-footer
    :single-expand="singleExpand"
    :expanded.sync="expanded"
    show-expand
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-dialog v-model="dialog" max-width="1000px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark small class="mb-2" v-bind="attrs" v-on="on" dense>
              Nuovo sito eCommerce
            </v-btn>
            <v-spacer></v-spacer>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4" >
                    <v-text-field v-model="editedItem.descrizione" label="Descrizione" :rules="rulesValidationRequired"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" >
                    <v-text-field v-model="editedItem.tipo" label="Tipo" :rules="rulesValidationRequired"></v-text-field>
                  </v-col>           
                  <v-col cols="12" sm="6" md="4" >
                    <v-text-field v-model="editedItem.url" label="Url" :rules="rulesValidationRequired"></v-text-field>
                  </v-col>                        
                  <v-col cols="12" sm="6" md="4" > 
                    <v-text-field v-model="editedItem.magazzino" label="Magazzino" :rules="rulesValidationEmail"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" > 
                    <v-text-field v-model="editedItem.listino" label="Listino" :rules="rulesValidationRequired"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" > 
                    <v-text-field v-model="editedItem.consumer_key" label="REST chiave cliente" :rules="rulesValidationRequired"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" > 
                    <v-text-field v-model="editedItem.consumer_secret" label="REST chiave segreta" :rules="rulesValidationRequired"></v-text-field>
                  </v-col>                                    
                  <v-col cols="12" sm="6" md="4">
                    <v-switch v-model="editedItem.abilitato" label="Attivo"></v-switch>
                  </v-col>                  
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="close"
              >
                Annulla
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="save"
              >
                Salva
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Sei sicuro di voler cancellare questo eCommerce?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Annulla</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:[`item.attivo`]="{ item }">
      <v-simple-checkbox v-model="item.attivo" disabled />
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      Nessun sito di eCommerce definito
    </template>
    <template v-slot:expanded-item="{ }">
    </template>
  </v-data-table>
</template>

<script>
  /* eslint-disable */
  import { validazioni } from '@/mixins/validazioni.js'
  
  export default {
    components: {
    },
    mixins: [
      validazioni
    ],
    props: {
      codiceCliente: String,
      siti: Array
    },
    data: () => ({
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: 'Descrizione',
          align: 'start',
          sortable: false,
          value: 'descrizione',
        },
        { text: 'Tipo', value: 'tipo' },
        { text: 'Url', value: 'url' },
        { text: 'Attivo', value: 'abilitato' },
        { text: 'Magazzino', value: 'magazzino' },
        { text: 'Listino', value: 'listino' },
        { text: 'Actions', value: 'actions', sortable: false },
        { text: '', value: 'data-table-expand' }
      ],
      updateTrigger: 0,
      editedIndex: -1,
      // TODO: RIVEDERE IL CARICAMENTO DEI MODULI E LA MODALITA' DI CREAZIONE DELLA DIALOG DI INSERIMENTO LICENZA--> METTERE COMPONENTI
      editedItem: {
        descrizione: '',
        tipo: '',
        magazzino: '',
        listino: '',
        attivo:  true,
        consumer_key: '',
        consumer_secret: '' // Non riportare, va mascherata per sicurezza !!
      },
      defaultItem: {
        descrizione: '',
        tipo: '',
        magazzino: '',
        listino: '',
        attivo:  true,
        consumer_key: '',
        consumer_secret: ''
      },
      expanded: [],
      singleExpand: false
    }),
    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nuovo sito eCommerce' : 'Modifica sito eCommerce'
      },
      rulesValidationRequired() {
        let rl = []
        rl.push(this.rules.required)
        return rl
      },
      rulesValidationEmail() {
        let rl = []
        rl.push(this.rules.required)
        rl.push(this.rules.email)
        return rl
      }    
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },
    methods: {
      editItem (item) {
        this.editedIndex = this.siti.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.siti.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
         this.$emit('delete-sito', this.codiceCliente, this.editedItem)
        this.siti.splice(this.editedIndex, 1)
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.siti[this.editedIndex], this.editedItem)
          this.$emit('update-sito', this.codiceCliente, this.editedItem)
        } else {
          this.$emit('new-sito', this.codiceCliente, this.editedItem)
          this.siti.push(this.editedItem)
        }
        this.close()
      }
    }
  }
</script>

<style lang="scss">
  tr.v-data-table__expanded__content  { // TODO: Solo su questa pagina !
    box-shadow: none !important;
  }
  
  td.utenteSelected {
    border-style: solid!important;
    border-width: 2px!important;
    border-color: #119432 !important;
  }
</style>

<template>
  <v-container>
    <v-row dense>
      <v-col cols="12" md="4" sm="12">
        <setup-generale :cliente="cliente" />
        <update-manuale :cliente="cliente" />
      </v-col>
      <v-col cols="12" md="4" sm="12">
        <download-moduli :cliente="cliente" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import SetupGenerale from './installazione/setup.vue'
  import UpdateManuale from './installazione/update.vue'
  import DownloadModuli from './installazione/moduli.vue'
  
  export default {
    components: {
      SetupGenerale,
      UpdateManuale,
      DownloadModuli
    },
    props: {
      cliente: Object
    },
    data() {
      return {

      }
    },
    methods: {

    }
  }
</script>

<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="databases"
      sort-by="key"
      class="elevation-1"
      dense
      :items-per-page="100"
      hide-default-footer
    >
      <template v-slot:top>
        <v-toolbar flat dense>
          <div class="text-h6">Database esistenti<v-btn icon @click="onRefresh" :loading="dbLoading"><v-icon>mdi-refresh</v-icon></v-btn></div>
          <v-spacer></v-spacer>
          <v-btn v-if="isUbico" depressed color="error" @click="onCancellaTutti">Cancella tutti i database</v-btn>          
          <v-dialog v-model="dialogEdit" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ 'Database :' + 'item' }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialogEdit=false">Chiudi</v-btn>
  <!--               <v-btn color="blue darken-1" text @click="save">Salva</v-btn> -->
              </v-card-actions>
            </v-card>
          </v-dialog>
  <!--         <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Sei sicuro di voler cancellare questa licenza?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">Annulla</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog> -->
        </v-toolbar>
      </template>
      
      <template v-slot:[`item.info.update_seq`]="{ item }">
        {{item.info && item.info.update_seq && item.info.update_seq.split('-')[0]}}
      </template>
  <!--     <template v-slot:[`item.licenza.dataScadenza`]="{ item }">
        {{item.licenza.dataScadenza | date}}
      </template>
      <template v-slot:[`item.licenza.dataAcquisto`]="{ item }">
        {{item.licenza.dataAcquisto | date}}
      </template>
      <template v-slot:[`item.licenza.attiva`]="{ item }">
        <v-simple-checkbox v-model="item.licenza.attiva" disabled />
      </template> -->
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small title="Modifica il database" class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
        <v-icon small title="Cancella il database e lo ricrea vuoto" class="mr-2" @click="onDeleteItem(item)">mdi-delete</v-icon>
        <v-icon small title="Prepara backup" class="mr-2" @click="onBackupItem(item)">mdi-cloud-download-outline</v-icon>
        <v-icon small title="Ripristina backup" class="mr-2" @click="restoreItem(item)">mdi-cloud-upload</v-icon>
        <a v-if="backuping === item.key" :href="backupFile" download @click="onDownload">Scarica il backup</a>
        <v-icon small title="Esporta su Excel" class="mr-2" @click="exportItem(item)">mdi-microsoft-excel</v-icon>
        <v-icon small title="Compatta il database" class="mr-2" @click="onCompactItem(item)">mdi-database-cog-outline</v-icon>
        <v-file-input
          v-if="restoring === item.key"
          class="mt-2 text-caption"
          accept=".json"
          label="File json"
          show-size
          outlined
          dense
          prepend-icon=""
          prepend-inner-icon="$file"
          @change="onChange">
        </v-file-input>	        
      </template>
      <template v-slot:no-data>
        Nessun database creato
      </template>
    </v-data-table>
    <dialog-conferma
      :show="dialogConferma"
      :title="titoloConferma"
      :message="messaggioConferma"
      :onlyOk="onlyOk"
      :confirmText="confirmText"
      @dialog-confirm="onConferma"
      @dialog-cancel="dialogConferma=false; onlyOk=false"
    />    
    <database-configurazioni-edit v-if="isDbConfigurazioni" :showEditor="showEditor" :dbName="dbName" :cliente="cliente" :istanzaDb="istanzaDb"/>
    <database-edit v-else :showEditor="showEditor" :dbName="dbName" :cliente="cliente" :istanzaDb="istanzaDb"/>
  </div>
</template>
<script>
  import databaseConfigurazioniEdit from './databaseEdit/editConfigurazioni.vue'
  import databaseEdit from './databaseEdit/edit.vue'
  import { rivenditore } from '@/mixins/rivenditore.js'
  import utility from '@/services/utilityService'
  import licenze from '@/services/licenzeService'
  import excelService from '@/services/excelService'
  import remote from '@/services/configurazioniRemoteService'
  // import services from '@/services/servicesService'
  import DialogConferma from '@/components/dialogConferma'

  export default {
    components: {
      databaseConfigurazioniEdit,
      databaseEdit,
      DialogConferma
    },
    props: {
      databases: Array,
      cliente: String,
      reseller: String,
      dbLoading: Boolean,
      baseDb: String
    },
    mixins: [
      rivenditore
    ],
    data: (vm) => ({
      dialogEdit: false,
      dialogDelete: false,
      toDelete: null,
      toBackup: null,
      toCompact: null,
      headers: [
        {
          text: 'Nome',
          align: 'start',
          sortable: false,
          value: 'key'
        },
        { text: 'Documenti', value: 'info.doc_count' },
        { text: 'Cancellati', value: 'info.doc_del_count' },
        { text: 'Dimensione', value: 'info.sizes.file' },
        { text: 'Aggiornamenti', value: 'info.update_seq' },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      editedIndex: -1,
      editedItem: {},
      showEditor: false,
      filename: '',
      backupFile: '',
      restore: {},
      restoring: '',
      backuping: '',
      dialogConferma: false,
      titoloConferma: '',
      messaggioConferma: '',
      onlyOk: false,
      onConferma: vm.operazioneEseguitaConfirm,
      confirmText: 'SI',
      istanzaDb: ''
    }),
    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      }
    },
    computed: {
      dbName() {
        return this.editedItem ? this.editedItem.key : ''
      },
      isDbConfigurazioni() {
        return this.dbName ? this.dbName.endsWith('configurazioni') : false
      },
      isUbico() {
        const utente = this.$store.getters['utenti/user']
        return utente.company === 'ubico'
      }         
    },
    async mounted() {
      this.istanzaDb = await this.getIstanzaDb(this.reseller)
    },
    methods: {
      async operazioneEseguita() {
        this.titoloConferma = 'Operazione eseguita'
        this.messaggioConferma = 'Operazione eseguita con successo !'
        this.onlyOk = true
        this.onConferma = this.operazioneEseguitaConfirm
        this.confirmText = 'OK'
        this.dialogConferma = false
        await this.$nextTick()
        this.dialogConferma = true
      },
      async operazioneEseguitaConfirm() {
        await this.cleanUp()
      },
      async cleanUp() {
        await this.$nextTick()
        this.dialogConferma = false
        this.onlyOk = false
        this.confirmText = 'SI'
      },      
      async editItem (item) {
        this.editedItem = item
        this.showEditor = true
        await this.$nextTick()
        this.showEditor = false
      },
      async onCancellaTutti() {
        this.titoloConferma = 'Cancellazione di tutti i database'
        this.messaggioConferma = 'Confermi la cancellazione di tutti i database ? <br/><br/>Attenzione !!! L\'operazione non è annullabile<br/>Assicurarsi di aver effettuato un backup'
        this.onConferma = this.deleteTutti
        this.dialogConferma = false
        await this.$nextTick()
        this.dialogConferma = true
      },
      async deleteTutti() {
        await licenze.removeAllDatabases(this.baseDb, this.istanzaDb)
        this.dialogConferma = false
        this.onRefresh()
        await this.operazioneEseguita()
      },      
      async onDeleteItem(item) {
        this.toDelete = item.key
        this.titoloConferma = 'Cancellazione database'
        this.messaggioConferma = 'Confermi cancellazione database ? <br/><br/>Attenzione !!! L\'operazione non è annullabile<br/>Assicurarsi di aver effettuato un backup'
        this.onConferma = this.deleteItem
        this.dialogConferma = false
        await this.$nextTick()
        this.dialogConferma = true
      },
      async deleteItem() {
        const dbname = this.toDelete
        await licenze.removeDatabase(dbname, this.istanzaDb)
        this.dialogConferma = false
        this.toDelete = ''
        this.onRefresh()
        await this.operazioneEseguita()
      },
      onBackupItem(item) {
        this.toBackup = item.key
        this.titoloConferma = 'Backup database'
        this.messaggioConferma = 'Confermi esecuzione backup database ?'
        this.onConferma = this.backupItem
        this.dialogConferma = true
      },      
      async backupItem () {
        const dbname = this.toBackup
        const filename = dbname + '.json'
        await this.cleanUp()
        let backupFile = await utility.backupDatabase(dbname, filename)
        if (!backupFile) {
          backupFile = `${dbname}.json`
        }
        this.backupFile = utility.getBackupLink(backupFile)
        this.backuping = dbname
        
        await this.operazioneEseguita()        
      },
      async restoreItem (item) {
        if (this.restoring === item.key) {
          this.restoring = ''
          return
        }
        const dbname = item.key
        this.restore[item.key] = true
        this.filename = dbname + '.json'
        this.restoring = item.key
      },
      async onChange(file) {
        if (!file) {
          return
        }
        this.loading = true
        const filename = this.filename
        const form_data = new FormData();
        form_data.append('upload_file', file, `${filename}`)
        const nomefile = await utility.uploadFileForm(form_data)
        this.dialogConferma = false
        const dbname = this.restoring
        await utility.restoreDatabase(dbname, filename)
        this.loading = false
        this.restoring = ''
        await this.operazioneEseguita() 
        this.onRefresh()
      },
      onDownload() {
        setTimeout(() => {
          this.backuping = ''
        }, 1000);
        return true
      },
      async exportItem (item) {
        const dbName = item.info.db_name
        const dati = await remote.getDatiGenerici(dbName, null, null, null)
        await excelService.creaExcelData(dati, `${dbName}.xlsx`)
      },
      onCompactItem(item) {
        this.toCompact = item.key
        this.titoloConferma = 'Compattazione database'
        this.messaggioConferma = 'Confermi esecuzione compattazione database ?'
        this.onConferma = this.compactItem
        this.dialogConferma = true
      }, 
      async compactItem () {
        const dbname = this.toCompact
        await this.cleanUp()
        await utility.compactDatabase(dbname)
        await this.operazioneEseguita()
        this.onRefresh()
      },
      onRefresh() {
        this.$emit('refresh')
      }
    }
  }
</script>
'use strict'
/* import errorService from '@/services/errorService'
import * as dot from 'dot-object'
 */
// import axios from 'axios'

const service = config.VUE_APP_service_migrazione || ''

async function inviaFileMdbForm(form_data) {
  try {
    const response = await fetch(`${service}/migrazione/db-file`, {
      method: 'POST',
      body: form_data
    })
    return  await response.text()
  } catch (err) {
    return err
  }
}

async function inviaFileMdb(dati, nomefile) {
  var data = {
    dati,
    nomefile
  }
  try {
    const response = await fetch(`${service}/migrazione/mdb-file`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    var a = response
    return await response.json()
  } catch (err) {
    return {}
  }
}

async function eseguiMigrazione(modello, id, nomefile, dbRemote, aggiornaDb, removeDb, nomefileDbc) {
  try {
    let data = {
      sqldb: nomefile,
      dbRemote,
      aggiornaDb,
      removeDb,
      sqldbDbc: nomefileDbc
    }
    const response = await fetch(`${service}/migrazione/${modello}/${id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    return await response.json()
  } catch (err) {
    return err
  }
}

async function statoMigrazione(modello) {
  try {
    const response = await fetch(`${service}/migrazione/${modello}/stato`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    return await response.json()
  } catch (err) {
    return err
  }
}

export default {
  inviaFileMdb,
  inviaFileMdbForm,
  eseguiMigrazione,
  statoMigrazione
}


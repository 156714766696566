<template>
  <div>
    <database-item :databases="infoDbs" :cliente="cliente && cliente.ragioneSociale" :baseDb="baseDb" :reseller="cliente.reseller" :dbLoading="dbLoading" class="mb-5" @refresh="aggiornaElencoDb"/>
    <v-row v-if="infoDbs.length === 0">
      <v-col cols="12" md="2"></v-col>
      <v-col cols="12" md="2">
        <v-btn depressed color="primary" @click="onCreaDbVuoti" :loading="vuotiLoading">
          Crea db Vuoti
        </v-btn>
      </v-col>         
      <v-col cols="12" md="4">
        <v-row>
          <v-col>
            <v-btn depressed color="success" @click="onCopiaDbEsistenti" :loading="esistentiLoading">
              Copia da progetto esistente
            </v-btn>
          </v-col>
          <v-col>
            <v-select :items="sorgentiSorted" label="Sorgente" outlined return-object v-model="sorgenteDb" dense item-text="codice"></v-select> 
          </v-col>
        </v-row>
        <!-- <v-text-field class="mt-3" v-model="sorgenteDb" label="Sorgente" outlined dense></v-text-field> -->
      </v-col>
      <v-col cols="12" md="2"></v-col>
      <v-col cols="12" md="2">
        <v-btn depressed color="info" @click="onCreaDbCollector" :loading="vuotiLoading">
          Crea db Collector
        </v-btn>
      </v-col>            
    </v-row>
    <dialog-conferma
      :show="dialogConferma"
      :title="titoloConferma"
      :message="messaggioConferma"
      :onlyOk="onlyOk"
      :confirmText="confirmText"
      @dialog-confirm="onConferma"
      @dialog-cancel="dialogConferma=false; onlyOk=false"
    />
  </div>
</template>

<script>
  import licenze from '@/services/licenzeService'
  import { rivenditore } from '@/mixins/rivenditore.js'
  import DialogConferma from '@/components/dialogConferma'
  import DatabaseItem from './databaseItem'
  // import SnippletElement from './snipplet.vue'

  export default {
    components: {
      DialogConferma,
      DatabaseItem
      // SnippletElement
    },
    props: {
      cliente: Object,
      elencoSorgenti: Array
    },
    mixins: [
      rivenditore
    ],    
    data (vm) {
      return {
        e6: 1,
        licenza: null,
        customerDbs: [],
        infoDbs:[],
        selectedCustomDb: 1,
        baseDb: '',
        sorgenteDb: null,
        dialogConferma: false,
        titoloConferma: '',
        messaggioConferma: '',
        onlyOk: false,
        onConferma: vm.creaDbVuoti,
        confirmText: 'SI',
        vuotiLoading: false,
        esistentiLoading: false,
        intervalStato: {},
        statusMessage: {
          copiaDatabase: ''       
        },
        istanzaDb: '',
        dbLoading: false
      }
    },  
    async mounted() {
      let baseDb = null
      if (this.cliente && this.cliente.licenze) {
        const licenza = this.cliente.licenze[0]
        this.licenza = licenza
        baseDb = licenza.baseDb
      } else {
        baseDb = this.cliente.baseDb
      }
      if (baseDb) {
        this.baseDb = baseDb
        await this.aggiornaElencoDb()
      }
    },
    computed: {
      impostazioni() {
        return this.licenza && this.licenza.impostazioni
      },
      impostazioniDatabase() {
        return this.impostazioni && this.impostazioni.database
      },
      sorgentiSorted() {
        const els = this.elencoSorgenti.filter(x => x.codice !== this.cliente.codice)
        return _.sortBy(els, ['codice'])
      }
    },
    methods: {
      operazioneEseguita() {
        this.titoloConferma = 'Operazione eseguita'
        this.messaggioConferma = 'Operazione eseguita con successo !'
        this.onlyOk = true
        this.onConferma = this.operazioneEseguitaConfirm
        this.confirmText = 'OK'
        this.dialogConferma = true
      },
      operazioneEseguitaConfirm() {
        this.dialogConferma = false
        this.confirmText= 'SI'
        this.onlyOk = false
      },
      async aggiornaElencoDb() {
        this.dbLoading = true
        this.istanzaDb = await this.getIstanzaDb(this.cliente.reseller)
        const data = await licenze.listCustomerDatabaseWithInfo(this.baseDb, this.istanzaDb)
        this.customerDbs = data.dbs
        this.infoDbs = data.info.result
        this.dbLoading = false
      },
      onCreaDbVuoti() {
        this.titoloConferma = 'Creazione nuovi database'
        this.messaggioConferma = 'Confermi creazione database vuoti per il progetto ?'
        this.onConferma = this.creaDbVuoti
        this.dialogConferma = true
      },
      async creaDbVuoti() {
        if (this.baseDb) {
          this.vuotiLoading = true
          this.istanzaDb = await this.getIstanzaDb(this.cliente.reseller)
          const apikey = this.cliente.licenze[0].apikey
          await licenze.creaDbVuoti(this.baseDb, this.istanzaDb, apikey)
          await this.aggiornaElencoDb()

          // Creazione ed associazione chiavi db ( poi visualizzare su licenza ma in sola lettura ... ) --> NO: fatta su creazione licenza
/*           const newcode = await licenze.creaCloudantApiPairs(this.baseDb, this.istanzaDb)
          this.$emit('update-credentials', { codice: this.cliente.codice, key: newcode.key, password: newcode.password }) */
          this.operazioneEseguita()
          this.vuotiLoading = false
        }
      },
      onCreaDbCollector() {
        this.titoloConferma = 'Creazione database per Collector'
        this.messaggioConferma = 'Confermi creazione database per Ubisell Collector ?'
        this.onConferma = this.creaDbCollector
        this.dialogConferma = true
      },
      async creaDbCollector() {
        if (this.baseDb) {
          this.vuotiLoading = true
          await licenze.creaDbCollector(this.baseDb)
          await this.aggiornaElencoDb()
          this.operazioneEseguita()
          this.vuotiLoading = false
        }
      },
      onCopiaDbEsistenti() {
        if (this.sorgenteDb) {
          this.titoloConferma = 'Creazione nuovi database'
          this.messaggioConferma = `Confermi copia database dal progetto: <strong>${this.sorgenteDb.baseDb}</strong> ?</br></br>Cliente: <strong>${this.sorgenteDb.ragioneSociale}</strong>`
          this.onConferma = this.copiaDbEsistenti
          this.dialogConferma = true
        }
      },
      async copiaDbEsistenti() {
        this.dialogConferma = false
        if (this.baseDb && this.sorgenteDb) {
          this.esistentiLoading = true
          const istanzaDbSorgente = await this.getIstanzaDb(this.sorgenteDb.reseller)
          const istanzaDbDestinazione = await this.getIstanzaDb(this.cliente.reseller)
          const apikey = this.cliente.licenze[0].apikey
          await licenze.copiaDbEsistenti(this.baseDb, this.sorgenteDb.baseDb, istanzaDbSorgente, istanzaDbDestinazione, apikey)
          this.verificaStatoElaborazione('copiaDabase', 'copiaDabase')
        }
      },
      verificaStatoElaborazione(id, tipo) {
        this.intervalStato[id] = setInterval(async () => {
          const stati = await licenze.statoElaborazione(id)
          const isRunning = stati[tipo]
          if (this.esistentiLoading) {
            this.statusMessage[id] = stati[`${tipo}Status`]
          }
          if (!isRunning) {
            await this.aggiornaElencoDb()
            this.operazioneEseguita()
            this.esistentiLoading = false
            clearInterval(this.intervalStato[id])
            this.intervalStato[id] = null
          } else {
            this.esistentiLoading = true
          }
        }, 10000)
      }      
/*       onAzioniStep2(id) {
        this.titoloConferma = 'Creazione impostazioni'
        this.messaggioConferma = `Confermi esecuzione azioni selezionate ?`
        this.onConferma = this.azioniStep2
        this.dialogConferma = true
        this[id] = true
      },
      async azioniStep2() {
        this.dialogConferma = false
        if (this.step2_ch1) {
          await licenze.createBaseSettings('base', `${this.baseDb}_configurazioni`)
          await licenze.aggiornaImpostazioniCliente(this.cliente.codice, this.licenza, 'impostazioni.database.impostazioni.base', true)
        }
        if (this.step2_ch2) {
          await licenze.createBaseSettings('benetton', `${this.baseDb}_configurazioni`)
          await licenze.aggiornaImpostazioniCliente(this.cliente.codice, this.licenza, 'impostazioni.database.impostazioni.custom.benetton', true)
        }
        await this.aggiornaElencoDb()
        this.operazioneEseguita()
      },
      onAzioniStep3(id) {
        this.titoloConferma = 'Creazione prodotti di base'
        this.messaggioConferma = `Confermi esecuzione azioni selezionate ?`
        this.onConferma = this.azioniStep3
        this[id] = true
        this.dialogConferma = true
      },
      async azioniStep3() {
        this.dialogConferma = false
        if (this.step3_ch1) {
          await licenze.copyDatabase('ubisell_prodotti_base', `${this.baseDb}_prodotti`)
          await licenze.aggiornaImpostazioniCliente(this.cliente.codice, this.licenza, 'impostazioni.database.dati.prodotti.buoni', true)
        }
        await this.aggiornaElencoDb()
        this.operazioneEseguita()
      } */
    }
  }
</script>

<template>
  <div>
    <v-container fluid dense>
      <v-row justify="start">
        <v-col md="2">
          <snipplet-element id="utility_05" categoria="Giacenza" :loading="loading['utility_05']" :statusMessage="statusMessage['utility_05']" :sceltaModifica="true" descrizione="Allinea la giacenza dei prodotti ai movimenti eseguiti" @select="onAzioniUtility"/>
        </v-col>
        <v-col md="2">
          <snipplet-element id="utility_06" categoria="Inventario da Giacenza" :loading="loading['utility_06']" :sceltaModifica="true" descrizione="Crea un movimento di inventario in base alla giacenza nel prodotto" @select="onAzioniUtility"/>
        </v-col>        
        <v-col md="2">
          <snipplet-element id="utility_01" categoria="Sanificazione Prodotti" descrizione="Elimina caratteri non ammessi su prodotti e database collegati" @select="onAzioniUtility"/>
        </v-col>
        <v-col md="2">
          <snipplet-element id="utility_07" categoria="Sanificazione Clienti" descrizione="Verifica sedi e recapiti" @select="onAzioniUtility"/>
        </v-col>
<!--         <v-col md="2">
          <snipplet-element id="utility_02" categoria="Correzione" descrizione="Corregge movimenti scontrino (solo versioni ante 05.11.2021)" @select="onAzioniUtility"/>
        </v-col> -->
<!--         <v-col md="2">
          <snipplet-element id="utility_04" categoria="Categorie" descrizione="Aggiunge categoria di default" @select="onAzioniUtility"/>
        </v-col>   -->       
        <v-col md="2">
          <snipplet-element
            id="utility_03"
            categoria="Prodotti obsoleti"
            :descrizione="descrizioneDataLimite"
            :dataVisible="true"
            dataLabel="Data limite movimenti"
            :dataValue="dataLimite"
            :loading="loading['utility_03']"
            :statusMessage="statusMessage['utility_03']"
            @select="onAzioniUtility"
            @change-date="onChangeDate"
          />
        </v-col>
        <v-col md="2">
          <snipplet-element id="utility_08" categoria="Crea categorie mancanti" :sceltaModifica="true" descrizione="Verifica eventuali categorie presenti sui prodotti" @select="onAzioniUtility"/>
        </v-col>          
        <v-spacer></v-spacer>
      </v-row>
    </v-container>
    <dialog-conferma
      :show="dialogConferma"
      :title="titoloConferma"
      :message="messaggioConferma"
      :onlyOk="onlyOk"
      :confirmText="confirmText"
      @dialog-confirm="onConferma"
      @dialog-cancel="operazioneEseguitaConfirm"
    />
  </div>    
</template>

<script>
  import licenze from '@/services/licenzeService'
  import utility from '@/services/utilityService'
  import configurazioni from '@/services/configurazioniRemoteService'
  import DialogConferma from '@/components/dialogConferma'
  import SnippletElement from './snipplet.vue'
  import moment from 'moment'

  export default {
    components: {
      DialogConferma,
      SnippletElement
    },
    props: {
      cliente: Object
    },
    data (vm) {
      return {
        dialogConferma: false,
        titoloConferma: '',
        messaggioConferma: '',
        onlyOk: false,
        onConferma: vm.azioniUtility,
        confirmText: 'SI',
        utility_01: false,
        utility_02: false,
        utility_03: false,
        utility_04: false,
        utility_07: false,
        utility_08: false,
        baseDb: null,
        dataLimite: moment().endOf('year').add(-2, 'year').toDate(), // .utcOffset(0, true).format() // Rappresenta l'ultimo giorno non valido,
        loading: {
          utility_03: false,
          utility_05: false
        },
        modificaDb: {},
        intervalStato: {},
        statusMessage: {
          utility_03: '',
          utility_05: ''
        }
      }
    },
    watch: {
      cliente(value) {
        if (value) {
          this.onRefresh()
        }
      }
    }, 
    async mounted() {
      let baseDb = null
      if (this.cliente && this.cliente.licenze) {
        const licenza = this.cliente.licenze[0]
        this.licenza = licenza
        baseDb = licenza.baseDb
      } else {
        baseDb = this.cliente.baseDb
      }
      this.baseDb = baseDb
      this.onRefresh()
    },
    computed: {
      descrizioneDataLimite() {
        // return 'Elimina i prodotti non movimentati dal ' + moment(this.dataLimite).add(1, 'day').format('L')
        return 'Elimina i prodotti non movimentati dalla data. Crea inventario per gli altri'
      }
    },
    methods: {
      onRefresh() {
        console.log('entrato')
        this.verificaStatoElaborazione('utility_03', 'obsoleti')
        this.verificaStatoElaborazione('utility_05', 'giacenza')
        // aggiungere altri eventuali
      },     
      operazioneEseguita(messaggio) {
        this.titoloConferma = 'Operazione eseguita'
        this.messaggioConferma = messaggio || 'Operazione eseguita con successo ! '
        this.onlyOk = true
        this.onConferma = this.operazioneEseguitaConfirm
        this.confirmText = 'OK'
        this.dialogConferma = true
      },
      operazioneEseguitaConfirm() {
        this.dialogConferma = false
        this.confirmText = 'SI'
        this.onlyOk = false
      },
      onAzioniUtility(id, modificaDb) {
        this.titoloConferma = 'Utility'
        this.messaggioConferma = `Confermi esecuzione azione selezionata ?`
        this.onConferma = this.azioniUtility
        this[id] = true
        this.modificaDb[id] = modificaDb
        this.dialogConferma = true
      },
      async azioniUtility() {
        this.dialogConferma = false
        let messaggio = ''
        let aggiorna = false
        if (this.utility_01) {
          this.loading['utility_01'] = true
          await licenze.sanitizeDb(`${this.baseDb}_prodotti`, true)
          this.loading['utility_01'] = false
        }
        if (this.utility_02) {
          await licenze.correggeMovimentiScontrino(`${this.baseDb}_movimenti`, true)
        }
        if (this.utility_03) {
          this.loading['utility_03'] = true
          await licenze.eliminaProdottiObsoleti(`${this.baseDb}_movimenti`, this.dataLimite, true)
          this.verificaStatoElaborazione('utility_03', 'obsoleti')
        }
        if (this.utility_04) {
          await licenze.aggiungeCategoriaDefault(`${this.baseDb}_prodotti`, true)
        }
        if (this.utility_05) {
          this.loading['utility_05'] = true
          const backoffice = await configurazioni.leggeConfigurazioneSub(`${this.baseDb}_configurazioni`, 'backoffice', 'impostazioni')
          const magazziniMultipli = backoffice.magazzino?.magazziniMultipli || false
          aggiorna = this.modificaDb['utility_05'] || false
          messaggio = await licenze.aggiornaGiacenza(`${this.baseDb}_prodotti`, aggiorna, magazziniMultipli)
          this.verificaStatoElaborazione('utility_05', 'giacenza')
          // this.loading['utility_05'] = false
        }  
        if (this.utility_06) {
          this.loading['utility_06'] = true
          const backoffice = await configurazioni.leggeConfigurazioneSub(`${this.baseDb}_configurazioni`, 'backoffice', 'impostazioni')
          const magazziniMultipli = backoffice.magazzino?.magazziniMultipli || false
          aggiorna = this.modificaDb['utility_06'] || false
          messaggio = await licenze.creaInventarioDaGiacenza(`${this.baseDb}_prodotti`, aggiorna, magazziniMultipli)
          this.loading['utility_06'] = false
        }
        if (this.utility_07) {
          this.loading['utility_07'] = true
          await licenze.sanitizeClienti(`${this.baseDb}_clienti`, true)
          this.loading['utility_07'] = false
        }
        if (this.utility_08) {
          this.loading['utility_08'] = true
          aggiorna = this.modificaDb['utility_08'] || false
          const result = await licenze.creaCategorieMancanti(`${this.baseDb}_prodotti`, aggiorna)
          if (!aggiorna) {
            messaggio = result.map(x => `${x.tipo}: ${x.codice} - ${x.descrizione}`).join('<br />')
          }
          this.loading['utility_08'] = false
        }         
        if (this.utility_03 || this.utility_05) {
          messaggio = 'L\'operazione è stata schedulata!<br/><br/>L\'attività richiede alcumi minuti per il completamento e sarà eseguita in background.'
        }
        this.operazioneEseguita(messaggio)
      },
      onChangeDate(value) {
        this.dataLimite = moment(value).toDate()
      },
      verificaStatoElaborazione(id, tipo) {
        // console.log('verificaStatoElaborazione', id)
        this.intervalStato[id] = setInterval(async () => {
          // const locId = id
          // console.log('verificaStatoElaborazione tmr', id)
          const stati = await utility.statoElaborazione(id)
          const isRunning = stati[tipo]
          if (this.loading[id]) {
            this.statusMessage[id] = stati[`${tipo}Status`]
          }
          if (!isRunning) {
            this.loading[id] = false
            // console.log('verificaStatoElaborazione off', id)
            clearInterval(this.intervalStato[id])
            this.intervalStato[id] = null
          } else {
            // console.log('verificaStatoElaborazione on', id)
            this.loading[id] = true
          }
        }, 10000)
      }
    }
  }
  </script>

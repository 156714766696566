<template>
  <v-container fluid dense>
    <v-row justify="start">
      <v-col md="2">
        <snipplet-migrazione-scelta @change="changeScelta"/>
      </v-col>
      <v-col md="2">
        <snipplet-migrazione
          id="anagrafica"
          dbRemote="clienti"
          categoria="Clienti e Fornitori"
          descrizione="Importa le anagrafiche di clienti e fornitori"
          :modello="modello"
          :cliente="cliente">
        </snipplet-migrazione>
      </v-col>
      <v-col md="2">
        <snipplet-migrazione
          id="prodotti"
          dbRemote="prodotti"
          categoria="Prodotti e Categorie"
          descrizione="Importa i prodotti e le relative categorie"
          :modello="modello"
          :cliente="cliente">
        </snipplet-migrazione>
      </v-col>
      <v-col md="2">
        <snipplet-migrazione
          id="causali"
          dbRemote="configurazioni"
          categoria="Causali di Magazzino"
          descrizione="Importa le causali di magazzino accodandole alle esistenti"
          :modello="modello"
          :cliente="cliente">
        </snipplet-migrazione>
      </v-col>
      <v-col md="2">
        <snipplet-migrazione
          id="movimenti"
          dbRemote="movimenti"
          :categoria="modello === 'magosimpr' ? 'Movimenti DB Server' : 'Movimenti e Scontrini'"
          :descrizione="modello === 'magosimpr' ? 'Importa movimenti DB Server' : 'Importa movimenti e scontrini'"
          :modello="modello"
          :cliente="cliente">
        </snipplet-migrazione>
      </v-col>
      <v-col md="2" v-if="modello === 'magosimpr'">
        <snipplet-migrazione
          id="scontrini"
          dbRemote="movimenti"
          categoria="Scontrini DB Cassa"
          descrizione="Importa scontrini DB Cassa"
          :modello="modello"
          :cliente="cliente">
        </snipplet-migrazione>
      </v-col>      
      <v-spacer></v-spacer>
    </v-row>
  </v-container>  
</template>

<script>
  import SnippletMigrazione from './snippletMigrazione.vue'
  import SnippletMigrazioneScelta from './snippletMigrazioneScelta.vue'

  export default {
    components: {
      SnippletMigrazione,
      SnippletMigrazioneScelta
    },
    props: {
      cliente: Object
    },
    data (vm) {
      return {
        modello: ''
      }
    },
    methods: {
      changeScelta(value) {
        this.modello = value
      }
    }
  }
  </script>

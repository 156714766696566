<template>
  <v-dialog v-model="dialogEditor" fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar dark color="primary" dense>
        <v-btn icon dark @click="dialogEditor = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Cliente: {{cliente}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text @click="dialogEditor = false">
            Chiudi
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <v-row align="stretch">
                <v-col cols="12" md="6">Database: {{dbName}}</v-col>
                <v-col cols="12" md="6" class="d-flex justify-end">
                  <v-btn color="success" @click="onSalvaJson">Salva</v-btn>
                  <v-btn color="error" class="ml-3 mr-2" @click="onAnnulla">Annulla</v-btn>
                  </v-col>
              </v-row>
            </v-list-item-title>
            <v-container fluid>
              <v-row align="stretch">
                <v-col cols="12" md="6" sm="12">
                  <configurazioni-list :dbName="dbName" :refreshTick="refreshTick" @change="onChangeItem"/>
                </v-col>          
                <v-col cols="12" md="6" sm="12">                  
                  <v-json-editor v-model="jsonWeb" :options="optionsWeb" :plus="true" @error="onErrorWeb" style="height: 100%;"/>
                </v-col>
              </v-row>
            </v-container>                       
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
    <dialog-conferma
      :show="dialogConferma"
      :title="titoloConferma"
      :message="messaggioConferma"
      :onlyOk="onlyOk"
      :confirmText="confirmText"
      @dialog-confirm="onConferma"
      @dialog-cancel="dialogConferma=false; onlyOk=false"
    />    
  </v-dialog>
</template>

<script>
  import configurazioni from '@/services/configurazioniRemoteService'
  import VJsonEditor from 'v-jsoneditor'
  import configurazioniList from './configurazioniList'
  import DialogConferma from '@/components/dialogConferma'
  export default {
    props: {
      dbName: String,
      cliente: String,
      showEditor: { type: Boolean, default: false }
    },
    components: {
      DialogConferma,
      VJsonEditor,
      configurazioniList
    },
    data (vm) {
      return {
        dialogEditor: false,
        jsonWeb: '',
        jsonMode: 'view',
        optionsWeb: {
          mode: this.jsonMode,
          modes: ['view', 'form', 'code'],
          search: true,
          enableSort: false,
          enableTransform: true
        },
        dialogConferma: false,
        titoloConferma: '',
        messaggioConferma: '',
        onlyOk: false,
        onConferma: vm.salvaJson,
        confirmText: 'SI',
        refreshTick: 0
      }
    },
    watch: {
      showEditor(value) {
        if (value && this.dbName.endsWith('configurazioni')) {
          this.dialogEditor = value
        }
      }
/*       jsonWeb() {
        const jEd = this.$refs.editor
        jEd.editor.setMode(this.jsonMode)
      } */
    },
    mounted() {
      this.jsonWeb = this.jsonContent || ''
      this.dialogEditor = this.showEditor
    },
    methods: {
      changeMode(mode) {
        const jEd = this.$refs.editor
        this.jsonMode = mode
        jEd.editor.setMode(mode)
      },
      onErrorWeb(error) {
        console.log(error)
      },
      operazioneEseguita() {
        this.titoloConferma = 'Operazione eseguita'
        this.messaggioConferma = 'Operazione eseguita con successo !'
        this.onlyOk = true
        this.onConferma = this.operazioneEseguitaConfirm
        this.confirmText = 'OK'
        this.dialogConferma = true
      },
      operazioneEseguitaConfirm() {
        this.dialogConferma = false
        this.onlyOk = false
        this.confirmText = 'SI'
      },      
      onSalvaJson() {
        this.titoloConferma = 'Salvataggio modifiche'
        this.messaggioConferma = `Confermi salvataggio modifiche ?`
        this.onConferma = this.salvaJson
        this.dialogConferma = true
      },
      async salvaJson() {
        const dati = this.jsonWeb
        await configurazioni.modificaConfigurazione(this.dbName, dati)
        this.dialogConferma = false
        this.refreshTick++
        this.operazioneEseguita()
      },
      onAnnulla() {
        this.refreshTick++
      },
      onChangeItem(dati) {
        this.jsonWeb = dati
      }
    }
  }
</script>

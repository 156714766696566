<template>
  <v-card class="mx-auto" max-width="300" min-height="380" outlined>
    <v-list-item three-line>
      <v-list-item-content>
        <div class="text-overline">Scelta modello sorgente</div>
        <v-list-item-subtitle>Selezionare il software dal quale migrare i dati</v-list-item-subtitle>
        <v-list-item-subtitle class="mt-3">Prestare particolare attenzione alla scelta, i tracciati non sono equivalenti</v-list-item-subtitle>
        <v-list-item-content>
          <v-list-item-title>
            <v-select
              v-model="select"
              :items="items"
              item-text="descrizione"
              item-value="codice"
              label="Origini disponibili"
              
              return-object
              single-line
              @change="onChange"
            />
<!--             persistent-hint
            :hint="`${select.note}`" -->
          </v-list-item-title>
          <v-list-item-content v-html="select.note"></v-list-item-content>
        </v-list-item-content>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>

<script>
export default {
  data(vm) {
    return {
      select: { state: '', descrizione: '', note: '' },
      items: [
        { codice: 'fatalight', descrizione: 'Fata light', note: 'Prodotti e barcode su dbm' },
        { codice: 'magosimpr', descrizione: 'Mago Simpr', note: 'Causali di magazzino, clienti e fornitori su dbc<br/><br/>Prodotti, movimenti e barcode su dbm server<br/><br/>Scontrini su dbm cassa' },
        { codice: 'bennet', descrizione: 'Bennet', note: 'Prodotti e barcode su dbc' },
        { codice: 'fataOttici', descrizione: 'Fata Ottici', note: 'Prodotti e barcode su dbm, articoli detraibili' }
      ]
    }
  },
  computed:{
  },
  methods: {
    onChange(item) {
      this.$emit('change', item.codice)
    }
  }
}
</script>
<template>
  <v-card class="mx-auto" max-width="300" outlined :loading="loading">
    <template slot="progress">
      <v-progress-linear color="deep-purple" height="3" indeterminate></v-progress-linear>
    </template>
    <v-list-item three-line>
      <v-list-item-content>
        <div class="text-overline">
          {{categoria}}
        </div>
        <v-list-item-subtitle>{{descrizione}}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-card-text class="pb-0" v-if="dataVisible">
      <p-data :item="dataItem" @change="onChangeData"/>
    </v-card-text>  
    <v-card-actions>
      <v-badge bordered :color="notAllowed ? 'error' : 'success'" :icon="icon" overlap :value="icon">
        <v-btn outlined text @click="onSeleziona" :disabled="disabled || notAllowed">{{comando}}</v-btn>
      </v-badge>
      <v-switch v-if="sceltaModifica" v-model="modificaDb" label="Aggiorna database" class="ml-3" dense></v-switch>

      
    </v-card-actions>
    <v-card-subtitle class="light-blue--text text--lighten-1">{{ statusMessage }}</v-card-subtitle>
  </v-card>
</template>

<script>
import pData from '@/views/drawer/elementi/data'

export default {
  components: {
    pData
  },
  props: {
    id: String,
    categoria: String,
    descrizione: String,
    comando: {type: String, default: 'Esegui'},
    disabled: Boolean,
    notAllowed: Boolean,
    dataVisible: { type: Boolean, default: false },
    dataLabel: String,
    dataValue: Date,
    loading: Boolean,
    sceltaModifica: Boolean,
    statusMessage: String
  },
  data(vm) {
    return {
      // loading: false, // TODO: implementare, per esecuzione comando,
      menu: false,
      modificaDb: false
    }
  },
  computed:{
    icon() {
      if (this.notAllowed) return 'mdi-lock'
      if (this.disabled) return 'mdi-check-outline'
      return ''
    },
    dataItem() {
      return {
        label: this.dataLabel,
        value: this.dataValue
      }
    }   
  },
  methods: {
    onSeleziona() {
      this.$emit('select', this.id, this.modificaDb)
    },
    onChangeData(item) {
      this.$emit('change-date', item.value)
    }
  }
}
</script>

<style scoped>
  .mx-auto {
    min-height: 270px;
  }
</style>
<template>
  <v-list dense>
    <v-list-item-group v-model="selectedItem" color="primary">
      <v-list-item v-for="(item, i) in items" :key="i" @click="seleziona(i)">
        <v-list-item-content>
          <v-list-item-title v-text="item"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>
<script>
  import configurazioni from '@/services/configurazioniRemoteService'
  export default {
    props: {
      dbName: String,
      refreshTick: Number
    },
    data (vm) {
      return {
        selectedItem: 0,
        elencoConfigurazioni: [],
        items: []
      }
    },
    watch: {
      async refreshTick() {
        await this.refresh()
      }
    },
    async mounted() {
      await this.refresh()
    },
    methods: {
      async refresh() {
        const elenco = await configurazioni.getElencoConfigurazioni(this.dbName, true)
        if (elenco && elenco.length > 0) {
          this.elencoConfigurazioni = elenco.map(x => x.doc)
          this.items = elenco.map(x => x.id).sort()
          this.seleziona(this.selectedItem)
        }
      },
      async seleziona(idx) {
        const dati = this.elencoConfigurazioni[idx]
        this.$emit('change', dati)
      }
    }
  }
</script>
<template>
  <v-card class="mt-5">
    <v-card-title class="text-h5">
      Aggiornamento manuale
    </v-card-title>
    <v-card-subtitle>Scegli le operazioni e scarica il batch da eseguire</v-card-subtitle>
    <v-row dense>
      <v-col>
        <v-list flat subheader three-line>
          <v-list-item-group v-model="settings" multiple active-class="">
          <v-list-item>
            <template v-slot:default="{ active }">
              <v-list-item-action>
                <v-checkbox :input-value="active"></v-checkbox>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Download programmi aggiornati</v-list-item-title>
                <v-list-item-subtitle>Scarica gli ultimi aggiornamenti</v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-list-item>
          <v-list-item>
            <template v-slot:default="{ active }">
              <v-list-item-action>
                <v-checkbox :input-value="active"></v-checkbox>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Installa i programmi aggiornati</v-list-item-title>
                <v-list-item-subtitle>Installa gli ultimi aggiornamenti</v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-list-item>
          <v-list-item>
            <template v-slot:default="{ active }">
              <v-list-item-action>
                <v-checkbox :input-value="active"></v-checkbox>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Scarica i file di configurazione del cliente</v-list-item-title>
                <v-list-item-subtitle>Necessari se vengono installati gli aggiornamenti</v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>
    </v-row>
    <v-card-actions>
      <v-btn class="ml-4 mb-2" link :href="'data:text/plain;charset=utf-8,' + encodeURIComponent(fileBatchContent)" download="aggiorna_manuale.bat">
        Scarica il batch
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import licenze from '@/services/licenzeService.js'
  export default {
    props: {
      cliente: Object
    },
    data() {
      return {
        settings: [0, 1, 2],
        moduli: ''
      }
    },
    async mounted() {
      if (this.cliente.licenze && this.cliente.licenze[0]) {
        this.moduli = await licenze.getElencoProgettiCliente(this.cliente.licenze[0].apikey)
        console.log(this.moduli)
      }
    },
    computed: {
      fileBatchContent() {
        let parametri = []
        if (this.settings.includes(0)) parametri.push('download')
        if (this.settings.includes(1)) parametri.push('install')
        if (this.settings.includes(2)) parametri.push('env')

        parametri.push(`apikey=${this.cliente.licenze && this.cliente.licenze[0].apikey}`) 
        let cont = []
        cont.push('@echo Ubisell - Aggiornamento manuale moduli')
        cont.push('@echo Avvio procedura aggiornamento ...')
        cont.push('')
        cont.push('@echo Arresto servizi ...')
        cont.push('@c:')
        cont.push('@cd c:\\ubisell\\bin')
        cont.push('for /F %%i in (moduli.txt) do (')
        // cont.push('set moduli=' + this.moduli )
        // cont.push('for %%i in (%moduli%) do (')
        cont.push('	@nssm stop %%i')
        cont.push(')')
        cont.push('@nssm stop http-server')
        cont.push('@nssm stop http-server-bo')
        cont.push(`@ubisellsu ${parametri.join(' ')}`)
        cont.push('')
        cont.push('for /F %%i in (moduli.txt) do (')
        // cont.push('for %%i in (%moduli%) do (')
        cont.push('  @nssm start %%i')
        cont.push(')')
        cont.push('@nssm start http-server')
        cont.push('@nssm start http-server-bo')
        cont.push('')
        cont.push('@echo Procedura aggiornamento completata')
        cont.push('@pause')

        return cont.join('\n')
      }
    }
  }
</script>

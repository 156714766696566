<template>
  <div>
    <v-row align="" class="mt-0">
      <v-col cols="12" md="11" sm="11">                  
        <v-json-editor v-model="queryText" :options="optionsWeb" :plus="true" @error="onErrorWeb" style="height: 100%;"/>
      </v-col>
    </v-row>
    <v-row align="stretch" class="mt-0">
      <v-col cols="12" md="11" class="d-flex justify-end mt-0">
        <v-btn color="success" @click="onEseguiQuery">Esegui</v-btn>
        <!-- <v-btn color="error" class="ml-3 mr-2" @click="onAnnulla">Annulla</v-btn> -->
        </v-col>
    </v-row>    
  </div>
<!--   <v-list flat subheader three-line>
    <v-subheader>Impostare eventuali filtri di visualizzazione</v-subheader>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>
          <v-json-editor v-model="queryText" :options="optionsWeb" :plus="true" @error="onErrorWeb" style="height: 100%;min-height: 500px;"/>
        </v-list-item-title>
        <v-list-item-subtitle>{{errorMessage}}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item> -->
<!--     <v-list-item-group v-model="selected" active-class="">
      <v-list-item>
        <template v-slot:default="{ active }">
          <v-list-item-action>
            <v-checkbox :input-value="active"></v-checkbox>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              <v-text-field label="Id record" clearable v-model="id"></v-text-field>
            </v-list-item-title>
          </v-list-item-content>
        </template>
      </v-list-item>
      <v-list-item>
        <template v-slot:default="{ active }">
          <v-list-item-action>
            <v-checkbox :input-value="active"></v-checkbox>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              <v-text-field label="Id start" clearable v-model="id_start"></v-text-field>
              <v-text-field label="Id end" clearable v-model="id_end"></v-text-field>
            </v-list-item-title>
          </v-list-item-content>
        </template>
      </v-list-item>      
    </v-list-item-group> -->
<!--   </v-list> -->
</template>

<script>
/*
  Per ogni tabella creare i campi filtro principali più alcune query di esempio + posso aggiungere o modificare una query
*/
  import VJsonEditor from 'v-jsoneditor'
  export default {
    props: {
      dbName: String,
      refreshTick: Number
    },
    components: {
      VJsonEditor
    },
    data (vm) {
      return {
        jsonMode: 'code',
        optionsWeb: {
          mode: this.jsonMode,
          modes: ['code'],
          search: false,
          enableSort: false,
          enableTransform: true
        },        
        selected: '',
        id: '',
        id_start: '',
        id_end: '',
        queryText: {
          _id : { $gt: "0" }
        },
        errorMessage: ''
      }
    },
    methods: {
      onErrorWeb(err) {
        this.errorMessage = err.message
      },
      onEseguiQuery() {
        this.$emit('change', this.queryText)
      }
    }
  }
</script>

<template>
  <v-data-table
    :headers="headers"
    :items="licenze"
    sort-by="tipo"
    class="elevation-1"
    dense
    hide-default-footer
    :single-expand="singleExpand"
    :expanded.sync="expanded"
    show-expand
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-dialog v-model="dialog" max-width="1000px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark small class="mb-2" v-bind="attrs" v-on="on" dense>
              Nuova licenza
            </v-btn>            
            <v-spacer></v-spacer>
          </template>
          <v-card :loading="loading">
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4" >
                    <!-- <v-text-field v-model="editedItem.tipo" label="Tipo" readonly></v-text-field> -->
                    <v-select :items="tipiLicenza" label="Tipo" v-model="editedItem.tipo" item-value="codice" item-text="descrizione" hide-details></v-select> 
                  </v-col>
                  <v-col cols="12" sm="6" md="4" >
                    <pApikey :apikey="editedItem.apikey" @change="onChangeApikey" @loading="setLoading"/>
                  </v-col>                
                  <v-col cols="12" sm="6" md="4" > 
                    <v-text-field v-model="editedItem.baseDb" label="Base DB" readonly></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" > 
                    <v-text-field v-model="dataScadenzaFormatted" label="Data scadenza" readonly></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" > 
                    <v-text-field v-model="editedItem.attivo" label="Attiva" readonly></v-text-field>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="12" sm="6" md="6" > 
                    <cloudant-apikey :apikey="editedItem.cloudantApikey" :baseDb="editedItem.baseDb" :istanzaDb="istanzaDb" @change="onChangeCloudant" @loading="setLoading"/>
                    <!-- <v-text-field v-model="editedItem.cloudantApikey" label="Cloudant Apikey" readonly></v-text-field> -->
                  </v-col>
                  <v-col cols="12" sm="6" md="6" > 
                    <v-text-field v-model="editedItem.cloudantPassword" label="Cloudant password" readonly></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" > 
                    <v-text-field v-model="editedItem.ipInstallazione" label="Ip installazione"></v-text-field>
                  </v-col>   
                  <v-col cols="12" sm="6" md="4" > 
                    <v-text-field v-model="editedItem.hashInstallazione" label="Hash installazione"></v-text-field>
                  </v-col>                         
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="close"
              >
                Annulla
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="save"
              >
                Salva
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="600px">
          <v-card>
            <v-card-title class="text-h5">Sei sicuro di voler cancellare questa licenza?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Annulla</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogRinnovo" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Confermi il rinnovo della licenza ?</v-card-title>
            <v-card-text>
              Verranno rinnovate le licenze per tutte le postazioni ed i moduli configurati
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeRinnova">Annulla</v-btn>
              <v-btn color="blue darken-1" text @click="rinnovaConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>        
        <v-btn color="success" dark small class="mb-2" @click="onRinnova" dense>
          Rinnova licenza
        </v-btn>        
      </v-toolbar>
    </template>
    <template v-slot:[`item.dataScadenza`]="{ item }">
      {{item.dataScadenza | date}}
    </template>
    <template v-slot:[`item.attivo`]="{ item }">
      <v-simple-checkbox v-model="item.attivo" disabled />
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      Nessuna licenza definita
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length" class="licenzaSelected">
        <dettaglio-moduli :moduli="item.moduli" :licenza="item" :tipoLicenzaCliente="item.tipoLicenza" :codiceCustom="item.codiceCustom" @update-modulo="updateModulo" @tipo-licenza="sceltaTipoLicenza"/>
      </td>
    </template>
  </v-data-table>
</template>

<script>
  /* eslint-disable */
  import pApikey from '@/views/drawer/elementi/apikey'
  import CloudantApikey from '@/views/drawer/elementi/cloudantApikey'
  import licenze from '@/services/licenzeService.js'
  import { rivenditore } from '@/mixins/rivenditore.js'
  import moment from 'moment'
  import DettaglioModuli from './moduli'

  export default {
    components: {
      DettaglioModuli,
      pApikey,
      CloudantApikey
    },
    props: {
      codiceCliente: String,
      licenze: Array,
      reseller: String
    },
    mixins: [
      rivenditore
    ],    
    data: () => ({
      dialog: false,
      dialogDelete: false,
      dialogRinnovo: false,
      headers: [
        {
          text: 'Tipo',
          align: 'start',
          sortable: false,
          value: 'tipo',
        },
        { text: 'Apikey', value: 'apikey' },
        { text: 'Base DB', value: 'baseDb' },
        { text: 'Data scadenza', value: 'dataScadenza' },
        { text: 'Attivo', value: 'attivo' },
        { text: 'Ip installazione', value: 'ipInstallazione' },
        { text: 'Hash Installazione', value: 'hashInstallazione' },
        { text: 'Actions', value: 'actions', sortable: false },
        { text: '', value: 'data-table-expand' }
      ],
      // licenze: [],
      itemListino: null,
      updateTrigger: 0,
      editedIndex: -1,
      // TODO: RIVEDERE IL CARICAMENTO DEI MODULI E LA MODALITA' DI CREAZIONE DELLA DIALOG DI INSERIMETNO LICENZA--> METTERE COMPONENTI
      editedItem: {
        tipo: 'customer',
        apikey: '',
        baseDb: '',
        dataScadenza:  moment().add(365, 'days').utcOffset(0, true).format(),
        attivo:  true,
        ipInstallazione:  '',
        hashInstallazione:  '',
        cloudantApikey: '',
        cloudantPassword: '',
        moduli: []
      },
      moduloBase: {
        nome: 'base',
        licenza: {
          tipo: 'customer',
          attiva: true,
          moduleKey: '',
          dataAcquisto: moment().utcOffset(0, true).format(),
          dataUltimoRinnovo: null,
          dataScadenza: moment().add(365, 'days').utcOffset(0, true).format(),
          pagamento: {}
        }
      },
      defaultItem: {
        tipo: 'customer',
        apikey: '',
        baseDb: '',
        dataScadenza: '',
        attivo: '',
        ipInstallazione: '',
        hashInstallazione: '',
        cloudantApikey: '',
        cloudantPassword: '',
        moduli: []
      },
      expanded: [],
      singleExpand: false,
      loading: false,
      istanzaDb: '',
      tipiLicenza: [
        { codice: 'customer', descrizione : 'Soluzione Ubisell'},
        { codice: 'totem', descrizione : 'Totem'}
      ]
    }),
    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nuova licenza' : 'Modifica licenza'
      },
      dataScadenzaFormatted() {
        const data = this.editedItem && this.editedItem.dataScadenza && moment(this.editedItem.dataScadenza).format('DD/MM/YYYY')
        return data
      }
    },
    watch: {
      dialog (open) {
        if (open) {
          this.editedItem.baseDb = this.editedItem.baseDb || this.codiceCliente
          this.editedItem.dataScadenza = this.editedItem.dataScadenza || moment().add(365, 'days').utcOffset(0, true).format()
          this.tipo = this.tipo || 'customer'
        } else {
          this.close()
        }
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },
    async mounted() {
      this.istanzaDb = await this.getIstanzaDb(this.reseller)      
    },
    methods: {
      setLoading(value) {
        this.loading = value
      },
      editItem (item) {
        this.editedIndex = this.licenze.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },
      deleteItem (item) {
        this.editedIndex = this.licenze.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        this.licenze.splice(this.editedIndex, 1)
        this.closeDelete()
      },
      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      onChangeApikey(value) {
        this.editedItem.apikey = value
      },
      onChangeCloudant({ key, password }) {
        this.editedItem.cloudantApikey = key
        this.editedItem.cloudantPassword = password
      },
      async save () {
        if (this.editedIndex > -1) {
          this.editedItem.moduli = this.editedItem.moduli || []
          if (this.editedItem.moduli.length === 0) { // TODO: Da rivedere
            let md = JSON.parse(JSON.stringify(this.moduloBase))
            if (this.editedItem.tipo === 'totem') {
              md.licenza.tipo = this.editedItem.tipo
              md.nome = this.editedItem.tipo
            }
            const key = await licenze.getShortApiKey()
            md.licenza.moduleKey = key.apikey
            this.editedItem.moduli.push(md)
          }
          Object.assign(this.licenze[this.editedIndex], this.editedItem)
          this.$emit('update-licenza', this.codiceCliente, this.editedItem)
        } else {
          // this.editedItem.moduli.push(this.moduloBase) // TODO: Da rivedere
          let md = JSON.parse(JSON.stringify(this.moduloBase))
          const key = await licenze.getShortApiKey()
          md.licenza.moduleKey = key.apikey
          if (this.editedItem.tipo === 'totem') {
              md.licenza.tipo = this.editedItem.tipo
              md.nome = this.editedItem.tipo
            }
          this.editedItem.moduli.push(md)
          this.$emit('new-licenza', this.codiceCliente, this.editedItem)
          this.licenze.push(this.editedItem)
        }
        this.close()
      },
      updateModulo(apikeyLicenza, modulo) {
        const licenza = this.licenze.find(x => x.apikey === apikeyLicenza)
        if (licenza) {
          const idx = licenza.moduli.findIndex(x => x.nome === modulo.nome)
          if (idx == -1) {
            licenza.moduli.push(modulo)
          } else {
            licenza.moduli[idx] = modulo
          }
          this.$emit('update-licenza', this.codiceCliente, licenza)
        }
      },
      async sceltaTipoLicenza(apikeyLicenza, tipo, codiceCustom) {
        let licenza = this.licenze.find(x => x.apikey === apikeyLicenza)
        if (licenza) {
          if (codiceCustom) {
            licenza.codiceCustom = codiceCustom
            delete licenza.tipoLicenza
          } else {
            licenza.tipoLicenza = tipo.codice
            delete licenza.codiceCustom
          }
          for (let m of tipo.moduli) {
            let modulo = { nome: m }
            modulo.licenza = { ...tipo.licenza }
            const key = await licenze.getShortApiKey()        
            modulo.licenza.moduleKey = key.apikey
            const idx = licenza.moduli.findIndex(x => x.nome === modulo.nome)
            if (idx == -1) {
              licenza.moduli.push(modulo)
            } else {
              licenza.moduli[idx] = modulo
            }
          }
          this.$emit('update-licenza', this.codiceCliente, licenza)
        }
      },
      onRinnova(item) {    
        this.dialogRinnovo = true
      },
      closeRinnova() {
        this.dialogRinnovo = false
      },
      rinnovaConfirm() {
        let licenza = this.licenze[0] // TODO: verificare licenze multiple !!
        licenza.dataScadenza = moment(licenza.dataScadenza).add(1, 'years').endOf('day').toISOString()
        for (let m of licenza.moduli) {
          if (m.licenza.attiva) {
            m.licenza.dataScadenza = licenza.dataScadenza
            m.licenza.rinnovi = m.licenza.rinnovi || []
            m.licenza.rinnovi.push({
              metodo: 'backofficeLicenze',
              tipologia: 'manuale',
              data: moment().toISOString(),
              fatturato: false
            })
          }
        }
        // TODO: Emettere messaggio / email per amministrazione --> rinnovo licenza !!!
        this.$emit('update-licenza', this.codiceCliente, licenza)
        this.closeRinnova()
      }
    }
  }
</script>

<style lang="scss">
  tr.v-data-table__expanded__content  { // TODO: Solo su questa pagina !
    box-shadow: none !important;
  }
  
  td.licenzaSelected {
    border-style: solid!important;
    border-width: 2px!important;
    border-color: #119432 !important;
  }
</style>
